<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="msgSend/web"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('newMsgSend.type')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('newMsgSend.status')">
          <el-select v-model.trim="search.status" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('newMsgSend.sendTime')">
          <el-date-picker
            v-model="dateRange"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :unlink-panels="true"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            :default-time="['00:00:00', '23:59:59']"
            @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="type" :label="$t('newMsgSend.type')" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 'msgPush'">
            {{ $t("newMsgSend.msgPush") }}
          </span>
          <span v-if="scope.row.type === 'sms'">
            {{ $t("newMsgSend.sms") }}
          </span>
          <span v-if="scope.row.type === 'email'">
            {{ $t("newMsgSend.email") }}
          </span>
          <span v-if="scope.row.type === 'wxMsgPush'">
            {{ $t("newMsgSend.wxMsgPush") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="content" :label="$t('newMsgSend.content')" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="referBizNo"
        :label="$t('newMsgSend.referBizNo')"
        align="center"
        width="120"></el-table-column>
      <el-table-column prop="target" :label="$t('newMsgSend.target')" width="120"></el-table-column>
      <el-table-column prop="status" :label="$t('newMsgSend.status')" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">{{$t('newMsgSend.sendSucceed')}}</el-tag>
          <el-tag v-if="scope.row.status === 2" type="danger">
            {{$t('newMsgSend.failSend')}}
            <el-popover
              v-if="scope.row.note !== '' && scope.row.note !== null"
              placement="top-start"
              width="200"
              trigger="hover"
              :content="scope.row.note"
              style="color: #FF4B00;">
              <i slot="reference" class="el-icon-warning"></i>
            </el-popover>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="sendTime"
        :label="$l('newMsgSend.sendTime', '发送时间')"
        align="center"
        width="140"></el-table-column>
    </vm-table>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        importLoading: false,
        loading: true,
        dateRange: [],
        search: {
          type: "",
          filter: "",
          status: "",
          startTime: "",
          endTime: "",
          isWeb: true,
        },
        typeOptions: [
          { value: "msgPush", label: this.$t("newMsgSend.msgPush") },
          { value: "sms", label: this.$t("newMsgSend.sms") },
          { value: "email", label: this.$t("newMsgSend.email") },
          { value: "wxMsgPush", label: this.$t("newMsgSend.wxMsgPush") },
        ],
        statusOptions: [
          { value: 0, label: this.$t("newMsgSend.notSend") },
          { value: 1, label: this.$t("newMsgSend.sendSucceed") },
          { value: 2, label: this.$t("newMsgSend.failSend") },
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
      clearDateRange() {
        this.dateRange = [];
      },
    },
  };
</script>
